<template>
   <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} |  تاق برس` : `تاق برس` }}</template>
  </metainfo>
 <a id="skippy" class="visually-hidden-focusable" href="#content">
    <div class="container">
      <span class="skiplink-text">Skip to main content</span>
    </div>
  </a>
  <router-view :key="$route.fullPath"/>
</template>
<script>
import { useMeta } from 'vue-meta'
  export default {
    name: 'App',
    setup () {
    useMeta({
      title: '',
      htmlAttrs: { lang: 'en', amp: true }
    })
  }
  }
</script>
<style>

</style>
