<template>
  <!--Footer start-->
    <footer>
      <!-- footer copyright menu -->
      <div class="footer-copyright bg-secondary">
        <div class="container">
          <!--Navbar-->
          <nav class="navbar navbar-expand navbar-dark px-0">
            <img class="footer-logo img-fluid mb-2" src="../../assets/img/Sudani_logo.png" alt="footer logo">
            <!--footer left menu-->
            <ul class="navbar-nav footer-nav text-center first-start-lg-0">
              <li class="nav-item"><router-link class="nav-link" to="About">حول</router-link></li>
              <!-- <li class="nav-item"><router-link class="nav-link" to="Contact">اتصل</router-link></li> -->
              <li class="nav-item"><router-link class="nav-link" to="About">شروط الاستخدام</router-link></li>
            </ul>
            <!--end footer left menu-->
            <!-- footer copyright -->
            <ul class="navbar-nav ms-auto text-center">
              
              <li class="d-inline navbar-text"> {{ year }} , Powered by © <a href="https://sudani.sd/">Sudani</a></li> 
            </ul>
            <!-- end footer copyright -->
          </nav>
          <!-- End Navbar -->
        </div>
      </div>
      <!--end copyright menu-->
    </footer>
    <!-- End Footer -->
</template>

<script>
export default {
    data() {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
@media (min-width:1440px){
  .footer-logo {
    width: 10%;
}

}
@media (min-width:1200px){
  .footer-logo {
    width: 10%;
}

}
@media (min-width:900px){
  .footer-logo {
    width: 10%;
}

}
@media (min-width:768px){
  .footer-logo {
    width: 5%;
}
}
@media (width:360px){
  .footer-logo {
    width: 30%;
}

}
</style>