import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'
import NotFound from '../views/Page/NotFound.vue'
function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: lazyLoad('Home')
  },
  {
    path: '/infographic',
    name: 'Infographic',
    component: () => import(/* webpackChunkName: "infographic" */ '../views/Page/Infographic.vue'),
  },
  {
    path: '/video/:id',
    name: 'Video',
    component: () => import(/* webpackChunkName: "video" */ '../views/Page/Video.vue'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/videoPlay/:id',
    name: 'VideoPlay',
    component: () => import(/* webpackChunkName: "videoPlay" */ '../views/Page/VideoPlay.vue'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/About.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Page/Contact.vue')
  },
  {
    path: '/policy',
    name: 'Policy',
    component: () => import(/* webpackChunkName: "policy" */ '../views/Page/Policy.vue')
  },
  {
    path: '/mix',
    name: 'Mix',
    component: () => import(/* webpackChunkName: "mix" */ '../views/Page/Mix.vue')
  },
  {
    path: '/health',
    name: 'Health',
    component: () => import(/* webpackChunkName: "health" */ '../views/Page/Health.vue')
  },
  {
    path: '/technology',
    name: 'Technology',
    component: () => import(/* webpackChunkName: "technology" */ '../views/Page/Technology.vue')
  },
  {
    path: '/caricature',
    name: 'Caricature',
    component: () => import(/* webpackChunkName: "caricature" */ '../views/Page/Caricature.vue')
  },
  {
    path: '/article',
    name: 'Article',
    component: () => import(/* webpackChunkName: "article" */ '../views/Page/Article.vue')
  },
  {
    path: '/economie',
    name: 'Economie',
    component: () => import(/* webpackChunkName: "economie" */ '../views/Page/Economie.vue')
  },
  {
    path: '/press',
    name: 'Press',
    component: () => import(/* webpackChunkName: "press" */ '../views/Page/Press.vue')
  },
  {
    path: '/sport',
    name: 'Sport',
    component: () => import(/* webpackChunkName: "sport" */ '../views/Page/Sport.vue')
  },
  {
    path: '/accident',
    name: 'Accident',
    component: () => import(/* webpackChunkName: "accident" */ '../views/Page/Accident.vue')
  },
  {
    path: '/breakingNews',
    name: 'BreakingNews',
    component: () => import(/* webpackChunkName: "breakingNews" */ '../views/Page/BreakingNews.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "news" */ '../views/Page/News.vue')
  },
  {
    path: '/internationalRegional',
    name: 'InternationalRegional',
    component: () => import(/* webpackChunkName: "internationalRegional" */ '../views/Page/InternationalRegional.vue')
  },
  {
    path: '/readArticle/:id',
    name: 'ReadArticle',
    component: () => import(/* webpackChunkName: "readArticle" */ '../views/Page/ReadArticle.vue'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/loginUser',
    name: 'LoginUser',
    component: () => import(/* webpackChunkName: "loginUser" */ '../views/User/LoginUser.vue')
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: () => import(/* webpackChunkName: "unsubscribe" */ '../views/User/Unsubscribe.vue'),
    meta: {
      IsAuth: true
    }
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})


router.beforeEach((to, from, next ) => {
  const { $cookie } = router.app.config.globalProperties
   if(to.meta.IsAuth) {
    if($cookie.isCookieAvailable("msisdn") && $cookie.isCookieAvailable("status")) {
      next();
    } else {
      // next('/Subscribe')
      next(window.open("http://sudanipay.com/subscription/tagpress/tagpress", "_self"));
    }
   } else {
     next();
   }
})

export default router
