import { createApp } from 'vue'
import App from './App.vue'
import fastclick from 'fastclick'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { VueCookieNext } from 'vue-cookie-next'
import lazyPlugin from 'vue3-lazy'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import Vue3Storage from "vue3-storage";
window.$ = window.jQuery = require('jquery');
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import Paginate from "vuejs-paginate-next";
import { createMetaManager } from 'vue-meta'

fastclick.attach(document.body)
const loadimage = require('./assets/img/Loading.gif')
const app = createApp(App);
app.use(store)
app.use(router)
router.app = app
app.use(VueAxios, axios)
app.use(VueCookieNext)
app.use(Toast)
app.use(Vue3Storage, { namespace: "tag_" })
app.use(VueVideoPlayer)
app.use(createMetaManager())
app.use(Paginate)
app.use(lazyPlugin, {
    preLoad: 1.3,
    loading: loadimage,
    attempt: 1
  })
app.mount('#app')
